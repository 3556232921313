import { getAuthState, getCurrentTsgId, getCurrentTsgToken, getState } from '@sparky/framework';

import { FetchStatus, PraMap, RequestMethods, RestAPI, RestModifiers, ZRestResponse } from '../data/queryResultsDefinition';
// import { buildEnvUrl } from './AppMgmtContext';
import { getStrings } from '../l18n/intlTools';

import Logger from './Logger';
import { ApiGeeEnv, apiGeeEnvData, makeQueryParams } from './urls';
import { getFawkesCertsDomain, getFawkesDomain, getZycadaDomain } from './utilities';

export const enum Products {
  ADEM = 'ADEM',
  ADEM_LICENSE = 'ADEM_LICENSE',
  FAWKES = 'FAWKES',
  FAWKES_JWT = 'FAWKES_JWT',
  COSMOS = 'COSMOS',
}

const apiGeeEnvSingleton = (() => {
  let apiGeeEnv: ApiGeeEnv | undefined = undefined;
  
  return {
      reset: (): void => {
        apiGeeEnv = undefined;
      },
      setEnv: (env: ApiGeeEnv): void => {
        apiGeeEnv = env;
      },
      getInstance: (): ApiGeeEnv | undefined => {
          return apiGeeEnv;
      },
  };
}) ();

function stringifyResponse(response: Response): string {
  return JSON.stringify({
    url: response.url,
    statusText: response.statusText,
    bodyUsed: response.bodyUsed,
    type: response.type,
    redirected: response.redirected,
    headers: { ...response.headers },
    status: response.status,
  })
}

const buildDefaultError = (response: Response): FetchStatus => {
  const STRs = getStrings() as PraMap<string>;

  return  {
    status: {
      error_message: STRs.serverError,
      http_status: response.status,
      ok: false,
    }
  }
}

const serverError = (response: Response): FetchStatus => {
  const STRs = getStrings() as PraMap<string>;
  Logger.error(stringifyResponse(response as Response));

  const errList: Record<string, string> = {
    '403': STRs.serviceNotInProj,
    '404': STRs.serverDataChanged,
    '500': STRs.serverError
  };

  let errStatus: FetchStatus = { status: {}};
  let responseJson = {}
  const panResponse = response as FetchStatus;
  
  const normalStatusCodes: PraMap<Boolean> = { '302': true, '400': true, '401': true, '403': true, '404': true, '419': true, '500': true}
  
  const statusCode = panResponse?.status?.error_code?.toString() ?? '500';

  if (normalStatusCodes[statusCode]) {
    responseJson = response.json();
    errStatus = responseJson as FetchStatus;
  } else {
    if (! response.bodyUsed) {
      responseJson = response.json();
      errStatus = responseJson as FetchStatus;
      if (!errStatus?.status?.ok) { 
        errStatus = buildDefaultError(response);
      }
    } else {
      errStatus = buildDefaultError(response);
    }
  }

  return errStatus;
}

interface InitRequestParams {
  method: string,
  modifiers?: RestModifiers
  restParams?: string | FormData,
  params?: RestAPI | string,
  url?: string,
}

const getDomain = (url: string | undefined, component: Products | undefined): string => {
  url = url ?? '';
  let domain: string | undefined = '';

  const authState = getAuthState();
  if (component === undefined) {
    domain = getZycadaDomain(authState)
  } else if (component === Products.FAWKES_JWT) {
    domain = getFawkesCertsDomain(authState)
  } else if (component === Products.ADEM || component === Products.ADEM_LICENSE || component === Products.COSMOS) {
    domain = getFawkesDomain(authState)
  }
  domain = domain ?? '';

  return domain + url;
}

const initRequest = async (initParams: InitRequestParams, component: Products | undefined): Promise<RequestInit> => {
  const jwt = await getCurrentTsgToken();

  const ourHeaders: [string, string][] = [
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['Authorization', `Bearer ${jwt}`],
  ]

  if (component === Products.ADEM || component === Products.ADEM_LICENSE) {
    const tsgId  = getCurrentTsgId();
    ourHeaders.push(['Prisma-Tenant', tsgId as string]);
  }

  if (component === undefined) {
    const region = getState().auth?.instances?.get('logging_service')?.region
    ourHeaders.push(['X-PANW-Region', region as string]);
  }

  if (component === Products.FAWKES || component === Products.FAWKES_JWT) {
    ourHeaders.push(['x-auth-jwt', jwt]);
  }

  const params: RequestInit = { headers: ourHeaders };

  if (initParams.modifiers) {
    const modifiers = initParams.modifiers;
    const headers: PraMap<string> = params.headers as PraMap<string>;
    if (headers) {
      if (modifiers.mimeType !== undefined && modifiers.mimeType.length > 0) {
        headers['Content-Type'] = modifiers.mimeType;
      } else {
        delete headers['Content-Type'];
      }

      if (modifiers.accept !== undefined && modifiers.accept.length > 0) {
        headers['Accept'] = modifiers.accept;
      } else {
        delete headers['Accept'];
      }
    }
    if (modifiers.noAuthKey) {
      delete params.credentials;
    }
  }

  const method = initParams.method.toLowerCase();
  Logger.info(`backend.initRequest:  method: ${method}, RequestMethods: ${RequestMethods[method]}`)
  params.method = RequestMethods[method];

  return params;
}

export async function ZGet( url: string, params: RestAPI | string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, params: makeQueryParams( '', params ), method: RequestMethods.get}
  return await HttpCommand(details, component);
}

export async function ZPost(url: string, restParams: string | FormData, modifiers?: RestModifiers, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.post}
  return await HttpCommand(details, component);
}

export async function ZDelete(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.delete}
  return await HttpCommand(details, component);
}

export async function ZPut(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.put}
  return await HttpCommand(details, component);
}

export async function ZPatch(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.patch}
  return await HttpCommand(details, component);
}

const buildFetchErrorStatus = (e: FetchStatus): FetchStatus => {
  let errState: FetchStatus = {status: {}}
  if (! e.status) {
    const STRs = getStrings() as PraMap<string>;
    errState = {
      status: {
        error_message: STRs.serverError,
        ok: false,
        http_status: 500
        }
    }
  } else {
    errState = e;
  }

  return errState;
}

const copyResp = (response: Response): FetchStatus => {
  let status: FetchStatus = {} as FetchStatus;
  if (typeof(response.status) === 'number') {
      status = { 
        status: {
          http_status: response.status,
          'http_status_message': response.statusText,
          ok: response.ok,
        }
      }
  } else {
    status = response as FetchStatus;
  }
  return status;
}

const buildEnvList = (url: string | undefined, component?: Products): 
                          { envList: string[] | ApiGeeEnv[], partialUrl: string, 
                            isMockUrl: boolean, hasEnv: boolean} => {
  let envList: string[] | ApiGeeEnv[] = apiGeeEnvData;

  let partialUrl = url ? url : '';
  let isMockUrl = false;
  let hasEnv = false;

  // get the environment string
  const envData = apiGeeEnvSingleton.getInstance();

  // check if it is a hard coded URL ('#') or if we really have our
  // environment (envData)
  //
  // if envData is undefined it means we haven't assigned an environment yet.
  if (envData || url?.charAt(0) === '#' || (component !== undefined && component !== Products.COSMOS)) {
    if (url?.indexOf('#') === -1) {
      if (component === undefined || component === Products.COSMOS) {
        envList = [envData as ApiGeeEnv];
        hasEnv = true;
      } else {                                  // urls for other components don't need environment
        envList = [''];                         // no need for an environment
      }
    } else {
      partialUrl = (url as string).slice(1);    // remove the # at the start of the URL
      envList = [''];                           // no need for an environment
      isMockUrl = true;
    }
  } 

  return {envList, partialUrl, isMockUrl, hasEnv};
}

export async function HttpCommand (requestDetails: InitRequestParams, component?: Products): Promise<any> {
  const params = await initRequest(requestDetails, component);

  const envListData = buildEnvList(requestDetails.url, component);
  const {envList, partialUrl, isMockUrl, hasEnv} = {...envListData}

  const body =  ((typeof requestDetails?.restParams === 'string' && requestDetails.restParams.length > 0)) ? 
                    requestDetails.restParams : '';
  if (body?.length > 0) {
    params.body = body;
  }

  const retryPrefix = partialUrl.indexOf('?') !== 0 ? '&' : '?';
  let response = {};
  for (let i=0, len=envList.length; i < len; i++) {
    try {
      let url = '';
        if (component === undefined) {
          url = (envList[i] as ApiGeeEnv).zos + partialUrl;
        } else if (component === Products.COSMOS) {
            url = (envList[i] as ApiGeeEnv).session + partialUrl;
        } 
        else {
            url = envList[i] + partialUrl;
        }

        if (i > 0) {
          url = url + `${retryPrefix}retry=${i}`;
        }
        const fullUrl = !isMockUrl ? getDomain(url, component) : partialUrl;
        response = await doFetch(fullUrl, params)

        if (! hasEnv) {
          apiGeeEnvSingleton.setEnv((envList[i] as ApiGeeEnv))
        }
        return response;
      }
      catch(e: any) {
        const err = e as FetchStatus;
        if (err?.status?.http_status === 404) {
            continue;
        }
        throw e;
    }
  }
  return Promise<void>;
}

const doFetch = async( fullUrl: string, params: RequestInit ): Promise<any> => {
  let respCopy = {};
  const promise = fetch(fullUrl, params)
    .then(response => {
        respCopy = copyResp(response)
        const lData = response.json();
        return lData;
    }).catch(e => {
      Logger.error('Error with HTTP request:', JSON.stringify(respCopy));
      throw buildFetchErrorStatus(respCopy as FetchStatus)
    })

  return promise;
}
